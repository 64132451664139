import popSmokeSong from '../sounds/pop-smoke-song.mp3';
import xxxtentacionSong from '../sounds/xxxtentacion-song.mp3';
import kingVonSong from '../sounds/king-von-song.mp3';
import biggieSong from '../sounds/biggie-song.mp3';
import tupacSong from '../sounds/tupac-song.mp3';
import juiceWrldSong from '../sounds/juice-wrld-song.mp3';
import takeoffSong from '../sounds/takeoff-song.mp3';
import nipseySong from '../sounds/nipsey-song.mp3';
import foolioSong from '../sounds/foolio-song.mp3';
import eazyeSong from '../sounds/eazye-song.mp3';
import youngdolphSong from '../sounds/youngdolph-song.mp3';
import macmillerSong from '../sounds/macmiller-song.mp3';
import dmxSong from '../sounds/dmx-song.mp3';
import macdreSong from '../sounds/macdre-song.mp3'; 
import mfdoomSong from '../sounds/mfdoom-song.mp3'; 
import coolioSong from '../sounds/coolio-song.mp3'; 

document.addEventListener('DOMContentLoaded', function() {

  function createAndAnimateStatic() {
    const animationContainer = document.createElement('div');
    
    // Set initial opacity and z-index
    const opacityValue = 0.17; 
    animationContainer.style = `
      flex: none;
      height: 100vh;
      left: 0;
      opacity: ${opacityValue};
      pointer-events: none;
      position: fixed;
      top: calc(50.00000000000002% - 100vh / 2);
      width: 100%;
      z-index: 99999999;  // Initially very high z-index
    `;
    
    const animationInnerContainer = document.createElement('div');
    animationInnerContainer.style = 'width:100%;height:100%;position:relative;overflow:hidden';
    
    const staticElement = document.createElement('div');
    staticElement.style = 'background: url("https://deadrapper.net/_static/static.png"); opacity: 0.5; inset: -200%; width: 400%; height: 400%; position: absolute;';
    
    function animateTransform() {
      const randomTransformX = Math.random() * 14 - 7;
      const randomTransformY = Math.random() * 14 - 7;
      staticElement.style.transform = `translateX(${randomTransformX}%) translateY(${randomTransformY}%) translateZ(0px)`;
    }

    setInterval(animateTransform, 100);
    animationInnerContainer.appendChild(staticElement);
    animationContainer.appendChild(animationInnerContainer);
    document.body.appendChild(animationContainer);

    return animationContainer; // Return animation container for later reference
  }

  // Create animation container and store reference
  const animationContainer = createAndAnimateStatic();


  let rappers = document.querySelectorAll('.rapper');
  let currentRapper = null;
  let audio = new Audio();
  let isMuted = false;

  function fadeOut(element, duration) {
    var interval = 10;
    var opacity = 1.0;
    var delta = interval / duration;

    var fading = setInterval(function () {
      if (opacity <= 0) {
        clearInterval(fading);
        element.style.display = 'none';
      }
      element.style.opacity = opacity;
      opacity -= delta;
    }, interval);
  }

  const rapperSongs = {
    'Pop Smoke': { file: popSmokeSong, title: 'Dior' },
    'XXXTentacion': { file: xxxtentacionSong, title: 'Revenge' },
    'King Von': { file: kingVonSong, title: 'Took Her To The O' },
    'The Notorious B.I.G.': { file: biggieSong, title: 'Big Poppa' },
    'Tupac': { file: tupacSong, title: 'Hit Em Up' },
    'Juice WRLD': { file: juiceWrldSong, title: 'Lucid Dreams' },
    'Takeoff': { file: takeoffSong, title: 'Hotel Lobby' },
    'Nipsey Hussle': { file: nipseySong, title: 'Double Up' },
    'Foolio': { file: foolioSong, title: 'When I See You' },
    'Eazy-E': { file: eazyeSong, title: 'Boyz-N-The-Hood' },
    'Young Dolph': { file: youngdolphSong, title: '100 Shots' },
    'Mac Miller': { file: macmillerSong, title: 'Self Care' },
    'DMX': { file: dmxSong, title: 'X Gon\' Give It To Ya' },
    'Mac Dre': { file: macdreSong, title: 'Feelin\' Myself' },
    'MF Doom': { file: mfdoomSong, title: 'Rapp Snitch Knishes' },
    'Coolio': { file: coolioSong, title: 'Gangsta\'s Paradise' }
  };

  document.getElementById('begin-btn').addEventListener('click', () => {
    fadeOut(document.getElementById('overlay'), 500); // 1 second fade out
    selectRandomRapper();
    audio.play();
    // Change the z-index back to 1001 when begin button is clicked
    animationContainer.style.zIndex = 1001;
  });

  function selectRandomRapper() {
    let nextRapper;
    do {
      nextRapper = rappers[Math.floor(Math.random() * rappers.length)];
    } while (nextRapper === currentRapper);
    
    if (currentRapper) currentRapper.classList.remove('active');
    nextRapper.classList.add('active');
    currentRapper = nextRapper;

    playRapperSong(currentRapper);
  }

function playRapperSong(rapper) {
  let rapperName = rapper.dataset.name;
  let songInfo = rapperSongs[rapperName];  // Get song info object

  audio.src = songInfo.file;  // Use the imported song file
  audio.play();

  // Create the ticker text
  const tickerText = `Now Playing: ${songInfo.title} by ${rapperName}`;
  
  // Dynamically calculate the number of repetitions needed
  const banner = document.getElementById('banner');
  const bannerWidth = banner.offsetWidth;
  const textElement = document.createElement('span');
  textElement.textContent = tickerText;
  textElement.style.paddingRight = '50px';
  
  // Temporarily append the text element to the banner to get its width
  banner.appendChild(textElement);
  const textWidth = textElement.offsetWidth;
  banner.removeChild(textElement);
  
  // Calculate the number of repetitions required
  const repeatCount = Math.ceil(bannerWidth / textWidth) + 2;  // +2 for buffer
  
  // Repeat the ticker text the necessary number of times
  let repeatedText = '';
  for (let i = 0; i < repeatCount; i++) {
    repeatedText += `<span class="ticker">${tickerText}</span>`;
  }

  // Update the banner with the repeated text
  banner.innerHTML = repeatedText;
  
  audio.onended = () => {
    selectRandomRapper();
  };
}

  rappers.forEach(rapper => {
    rapper.addEventListener('click', () => {
      if (currentRapper !== rapper) {
        currentRapper.classList.remove('active');
        rapper.classList.add('active');
        currentRapper = rapper;
        playRapperSong(currentRapper);
      }
    });
  });

  document.getElementById('sound-control').addEventListener('click', () => {
    isMuted = !isMuted;
    audio.muted = isMuted;
    document.getElementById('sound-control').textContent = isMuted ? 'Unmute' : 'Mute';
  });

  // Handle Copy Button for Contract Address
  const copyBtn = document.getElementById('copy-btn');
  const contractAddress = document.getElementById('contract-address').textContent;
  const copyStatus = document.getElementById('copy-status');

  copyBtn.addEventListener('click', function() {
    // Copy contract address to clipboard
    navigator.clipboard.writeText(contractAddress).then(function() {
      // Show the "Copied!" checkmark
      copyStatus.style.display = 'inline';
      
      // Hide the checkmark after 2 seconds
      setTimeout(function() {
        copyStatus.style.display = 'none';
      }, 2000);
    }).catch(function(err) {
      console.error('Could not copy text: ', err);
    });
  });

});

console.log("%cPOUR ONE OUT FOR THOSE GONE TOO SOON 🪦", "font-size: 18px; font-family: 'Helvetica', Arial, sans-serif; color:#fff; text-shadow: 2px 2px 4px #000000; font-weight: bold;");